import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  FullPhoto,
  Hero,
  Logo,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
} from "components/CaseLayout";
import { MATTHIAS } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Matthias = () => {
  const data = useCaseData(MATTHIAS);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo}/>
      <Hero data={data.hero}/>
      <Navigation type={MATTHIAS}/>
      <WhatWeDid data={data.what_we_did}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox data={data.text_boxes.first}/>
      <Logo data={data.logo.first}/>
      <FullPhoto data={data.full_images.second}/>
      <FullPhoto data={data.full_images.third}/>
      <TextBox data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.fourth}/>
      <FullPhoto data={data.full_images.fifth}/>
      <FullPhoto data={data.full_images.sixth}/>
      <TextBox background="#FF3233" color="#fff" data={data.text_boxes.third}/>
      <FullPhoto data={data.full_images.seventh}/>
      <FullPhoto data={data.full_images.eighth}/>
      <FullPhoto data={data.full_images.ninth}/>
      <FullPhoto data={data.full_images.tenth}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.fourth}/>
      <FullPhoto data={data.full_images.eleventh}/>
      <TextBox background="#111111" color="#fff" data={data.text_boxes.fifth}/>
      <FullPhoto data={data.full_images.twelfth}/>
      <FullPhoto data={data.full_images.thirteenth}/>
      <ContactForm/>
    </Layout>
  );
};

export default Matthias;
